import * as dayjs from "dayjs";
import {
  Wakatime,
  WakatimeDataGrand_Total,
  Maybe,
  WakatimeDataLanguages,
} from "../types/graphql-types";

export type Edge = {
  node: Pick<Wakatime, "id"> & {
    data?: Maybe<{
      languages?: Maybe<
        Array<Maybe<Pick<WakatimeDataLanguages, "name" | "total_seconds">>>
      >;
      grand_total?: Maybe<Pick<WakatimeDataGrand_Total, "total_seconds">>;
    }>;
  };
};

export const filterRangeByYear = (year: number | string, unixTime: number) => {
  const start = dayjs(`${year}-01-01 00:00:00`, "YYYY-MM-DD HH:mm:ss").unix();
  const end = dayjs(`${year}-12-31 23:59:59`, "YYYY-MM-DD HH:mm:ss").unix();

  return unixTime >= start && unixTime <= end;
};

export const filteredYear = (edges: Edge[], subtract: number) =>
  filteredData(edges, subtract, "year");

export const filteredMonth = (edges: Edge[], subtract: number) =>
  filteredData(edges, subtract, "month");

const filteredData = (
  edges: Edge[],
  subtract: number,
  unit: dayjs.OpUnitType
) => {
  const start = dayjs().subtract(subtract, unit).startOf(unit);
  const end = dayjs().subtract(subtract, unit).endOf(unit);
  return edges.filter(({ node }) => {
    const ts = dayjs(node.id).unix();
    return ts >= start.unix() && ts <= end.unix();
  });
};
