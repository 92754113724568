import React, { FC } from "react";
import Layout from "../components/layout";
import YearDetail from "../sections/year-detail";
import CalendarHeatmap from "../components/calendar-heatmap";
import MonthDetail from "../sections/month-detail";

const Container: FC = () => (
  <Layout>
    <section className="section">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <CalendarHeatmap />
        </div>
      </div>
    </section>
    <YearDetail />
    <MonthDetail />
  </Layout>
);

export default Container;
