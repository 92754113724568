import React, { FC, useState, useCallback } from "react";
import { graphql, StaticQuery } from "gatsby";
import { Line } from "react-chartjs-2";
import Pie from "../components/pie";
import * as dayjs from "dayjs";
import humanize from "humanize-time";
import { filteredMonth } from "../date-range";

import { MonthDetailQuery } from "../../types/graphql-types";

type Props = {
  data: MonthDetailQuery;
};

const Container: FC<Props> = ({ data }) => {
  const [subtract, setSubtract] = useState(0);
  const start = dayjs().subtract(subtract, "month").startOf("month");
  const end = dayjs().subtract(subtract, "month").endOf("month");

  const prev = useCallback(() => {
    setSubtract((prev) => prev + 1);
  }, []);

  const next = useCallback(() => {
    if (subtract <= 0) {
      return;
    }

    setSubtract((prev) => prev - 1);
  }, [subtract]);

  const filteredData = filteredMonth(data.allWakatime.edges, subtract);
  const totalTime = humanize(
    Math.round(
      filteredData.reduce(
        (pre, { node }) => (pre += node.data.grand_total.total_seconds),
        0
      ) * 1000
    )
  );

  const lineData = {
    labels: filteredData.map(({ node }) => dayjs(node.id).format("MM/DD")),
    datasets: [
      {
        label: "",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: filteredData.map(
          ({ node }) => node.data.grand_total.total_seconds
        ),
      },
    ],
  };

  return (
    <section className="hero">
      <div className="hero-body">
        <div className="columns has-text-centered">
          <div className="column is-10 is-offset-1">
            <h1 className="title">月ごとの集計</h1>
          </div>
        </div>
        <div className="columns is-mobile is-vcentered">
          <div className="column is-4 has-text-right">
            <button className="button" onClick={prev}>
              {"<"}
            </button>
          </div>
          <div className="column is-4 has-text-centered">
            <h2 className="subtitle">
              <span>
                {start.format("YYYY-MM-DD")} ~ {end.format("YYYY-MM-DD")}
              </span>
            </h2>
          </div>
          <div className="column is-4 has-text-left">
            <button className="button" onClick={next}>
              {">"}
            </button>
          </div>
        </div>

        <div className="columns has-text-centered">
          <div className="column is-10 is-offset-1">
            <h1 className="title">{totalTime}</h1>
          </div>
        </div>

        <div className="columns">
          <div className="column is-10 is-offset-1 columns">
            <div className="column is-6">
              <Line
                data={lineData}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          min: 0,
                          max: 35000,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
            <div className="column is-6">
              <Pie edges={filteredData} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query MonthDetail {
        allWakatime {
          edges {
            node {
              id
              data {
                languages {
                  name
                  total_seconds
                }
                grand_total {
                  total_seconds
                }
              }
            }
          }
        }
      }
    `}
    render={(data: MonthDetailQuery) => <Container data={data} />}
  />
);
