import React, { FC } from "react";
import { Edge } from "../date-range";
import sortBy from "lodash.sortby";
import { Pie } from "react-chartjs-2";

const Container: FC<{ edges: Edge[] }> = ({ edges }) => {
  const languageData = edges.reduce((prev, { node }) => {
    node.data.languages.forEach(({ name, total_seconds }) => {
      if (!prev[name]) {
        prev[name] = 0;
      }
      prev[name] += total_seconds;
    });
    return prev;
  }, {});

  const langData = sortBy(
    Object.keys(languageData).map((name) => ({
      name,
      total_seconds: languageData[name],
    })),
    "total_seconds"
  ).reverse();

  const data = {
    labels: langData.map((lang) => lang.name),
    datasets: [
      {
        data: langData.map((lang) => lang.total_seconds),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };
  return <Pie data={data} />;
};

export default Container;
