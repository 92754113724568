import React, { FC } from "react";
import { navigate, graphql, StaticQuery } from "gatsby";
import CalendarHeatmap from "react-calendar-heatmap";
import dayjs from "dayjs";
import Tooltip from "react-tooltip";
import humanize from "humanize-duration";
import "react-calendar-heatmap/dist/styles.css";
import style from "./calendar-heatmap.module.css";
import { CalendarHeatmapQuery } from "../../types/graphql-types";

type Value = {
  date: string;
  count: number;
};

type Props = {
  values: Value[];
};

const Component: FC<Props> = ({ values }) => (
  <div>
    <CalendarHeatmap
      startDate={dayjs().subtract(1, "day").subtract(1, "year").toDate()}
      endDate={dayjs().subtract(1, "day").toDate()}
      values={values}
      classForValue={(value) => {
        if (!value || !value.count || value.count <= 0) {
          return style.colorEmpty;
        }
        if (value.count > 20000) {
          return style.colorScale4;
        }
        if (value.count > 10000) {
          return style.colorScale3;
        }
        if (value.count > 5000) {
          return style.colorScale2;
        }
        return style.colorScale1;
      }}
      tooltipDataAttrs={(value: Value) => ({
        "data-tip": `${value.date}: ${humanize(value.count * 1000, {
          language: "ja",
        })}`,
      })}
      onClick={(value: Value) => navigate(`/logs/${value.date}`)}
    />
    <Tooltip />
  </div>
);

const Container: FC = () => (
  <StaticQuery
    query={graphql`
      query CalendarHeatmap {
        allWakatime {
          edges {
            node {
              id
              data {
                grand_total {
                  total_seconds
                }
              }
            }
          }
        }
      }
    `}
    render={(data: CalendarHeatmapQuery) => {
      const values = data.allWakatime.edges.map(({ node }) => ({
        date: node.id,
        count: Math.round(node.data.grand_total.total_seconds),
      }));
      return <Component values={values} />;
    }}
  />
);

export default Container;
