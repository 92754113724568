import React, { FC, useState, useCallback } from "react";
import { graphql, StaticQuery } from "gatsby";
import * as dayjs from "dayjs";
import humanize from "humanize-time";
import { filteredYear, Edge } from "../date-range";
import { YearDetailQuery } from "../../types/graphql-types";
import { Bar } from "react-chartjs-2";
import Pie from "../components/pie";

const MonthBar: FC<{ edges: Edge[] }> = ({ edges }) => {
  const monthData = edges.reduce((prev, { node }) => {
    const date = dayjs(node.id).format("M") + "月";
    if (!prev[date]) {
      prev[date] = 0;
    }
    prev[date] += node.data.grand_total.total_seconds;
    return prev;
  }, {});

  const data = {
    labels: Object.keys(monthData),
    datasets: [
      {
        label: "",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: Object.keys(monthData).map((month) => monthData[month]),
      },
    ],
  };

  return (
    <Bar
      data={data}
      options={{
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 450000,
              },
            },
          ],
        },
      }}
    />
  );
};

type Props = {
  data: YearDetailQuery;
};

const Container: FC<Props> = ({ data }) => {
  const [subtract, setSubtract] = useState(0);

  const year = dayjs().subtract(subtract, "year").format("YYYY");

  const prev = useCallback(() => {
    setSubtract((prev) => prev + 1);
  }, []);

  const next = useCallback(() => {
    if (subtract <= 0) {
      return;
    }

    setSubtract((prev) => prev - 1);
  }, [subtract]);

  const filteredData = filteredYear(data.allWakatime.edges, subtract);

  const totalTime = humanize(
    Math.round(
      filteredData.reduce(
        (pre, { node }) => (pre += node.data.grand_total.total_seconds),
        0
      ) * 1000
    )
  );

  return (
    <section className="hero">
      <div className="hero-body">
        <div className="columns has-text-centered">
          <div className="column is-10 is-offset-1">
            <h1 className="title">年ごとの集計</h1>
          </div>
        </div>
        <div className="columns is-mobile is-vcentered">
          <div className="column is-4 has-text-right">
            <button className="button" onClick={prev}>
              {"<"}
            </button>
          </div>
          <div className="column is-4 has-text-centered">
            <h2 className="subtitle">
              <span className="is-size-4">{year}年</span>
            </h2>
          </div>
          <div className="column is-4 has-text-left">
            <button className="button" onClick={next}>
              {">"}
            </button>
          </div>
        </div>

        <div className="columns has-text-centered">
          <div className="column is-10 is-offset-1">
            <h1 className="title">{totalTime}</h1>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-10 is-offset-1 columns">
          <div className="column is-6">
            <MonthBar edges={filteredData} />
          </div>
          <div className="column is-6">
            <Pie edges={filteredData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query YearDetail {
        allWakatime {
          edges {
            node {
              id
              data {
                languages {
                  name
                  total_seconds
                }
                grand_total {
                  total_seconds
                }
              }
            }
          }
        }
      }
    `}
    render={(data: YearDetailQuery) => <Container data={data} />}
  />
);
